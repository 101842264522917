export const ProfileSvg = () => {
  return (
    <svg className="px-1" xmlns="http://www.w3.org/2000/svg" width="49" height="50" fill="none" viewBox="0 0 49 50">
      <rect width="27.667" height="29.2" x="11.017" y="10.4" stroke="#6B42EE" strokeWidth="3" rx="3.5"></rect>
      <path
        stroke="#6B42EE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M34.646 38.8v-2.47a4.94 4.94 0 00-4.94-4.941h-9.882a4.94 4.94 0 00-4.94 4.94v2.47M24.765 26.448a4.94 4.94 0 100-9.881 4.94 4.94 0 000 9.881z"
      ></path>
      <g stroke="#6B42EE" strokeLinecap="round" strokeWidth="3" opacity="0.4">
        <path d="M4.917 5.067L7.6 7.75M40.95 41.867l3.45 3.45M24.85 44.167V48M24.85 5.833V2M40.95 7.75l3.067-3.067M5.683 45.7l3.067-3.067M42.717 26.15h4.216M6.6 26.15H2"></path>
      </g>
    </svg>
  );
};

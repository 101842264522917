export const BeachSvg = ({ selected }: { selected: boolean }) => {
  return (
    <svg
      className={`${selected ? "" : "opacity-60"} mt-2`}
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="20"
      fill="none"
      viewBox="0 0 53 23"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="2"
        d="M51.5 21c.5-4-2.429-9-8-9S36 17.35 36 20"
        opacity="0.6"
      ></path>
      <path stroke="#000" strokeLinecap="round" strokeWidth="2" d="M14 9.5l2.674 11.57"></path>
      <path
        stroke="#000"
        strokeWidth="2"
        d="M12.24 1.663C4.874 3.57 1.79 8.608 1.072 11.151c-.038.136.082.26.222.24 5.218-.733 5.726.924 7.002.883 1.3-.042 2.14-2.097 5.788-3.104 3.648-1.006 5.494.126 6.416 0 .923-.126 1.93-1.761 2.978-2.6 1.049-.839 3.02-.923 3.313-1.552.294-.629-4.99-5.83-14.553-3.355zM14.882 1.168c1.985.447 5.921 2.667 5.787 7.968M10.394 2.216c-1.314 1.356-3.548 5.251-1.97 9.982"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="2"
        d="M30 20.41c.258.53 1.134 1.59 2.576 1.59 1.803 0 2.216-1.59 5.152-1.59 2.937 0 2.731 1.59 4.534 1.59 1.804 0 1.958-1.916 4.431-1.998C49.166 19.921 49.063 22 52 22M11 21.745h15.591"
      ></path>
    </svg>
  );
};

export const CoffeeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="94" fill="none" viewBox="0 0 65 94">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M60.082 25.596L52.136 87.32a3.716 3.716 0 01-1.368 2.435C47.628 92.253 40.422 94 32.034 94c-8.388 0-15.594-1.747-18.733-4.245a3.716 3.716 0 01-1.369-2.435L7.96 56.458 3.986 25.596h56.096z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#000"
        strokeOpacity="0.08"
        d="M4.555 26.096h54.959l-7.874 61.16a3.216 3.216 0 01-1.183 2.108c-1.485 1.18-3.986 2.22-7.2 2.963-3.201.74-7.061 1.173-11.223 1.173-4.16 0-8.021-.433-11.222-1.173-3.214-.742-5.716-1.782-7.2-2.963a3.215 3.215 0 01-1.184-2.108L8.455 56.394l-3.9-30.298z"
      ></path>
      <path
        fill="#302B26"
        fillRule="evenodd"
        d="M1.01 15.722S0 19.348 0 20.733c0 5.555 14.342 10.058 32.034 10.058 17.693 0 32.035-4.503 32.035-10.058 0-1.385-1.01-5.011-1.01-5.011H1.01z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#494139"
        fillRule="evenodd"
        d="M58.736 11.493c2.81 1.468 4.425 3.187 4.425 5.025 0 5.397-13.936 9.772-31.127 9.772S.907 21.915.907 16.518c0-1.838 1.616-3.557 4.425-5.025h53.404z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#302B26"
        fillRule="evenodd"
        d="M57.379 5.933c.545.716.955 1.514 1.879 9.937a3.48 3.48 0 01-1.054 2.907c-3.355 3.182-13.802 5.47-26.17 5.47-12.368 0-22.815-2.288-26.17-5.47a3.478 3.478 0 01-1.053-2.907c.924-8.423 1.333-9.22 1.88-9.937h50.688z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#494139"
        fillRule="evenodd"
        d="M57.839 7.315c0 4.04-11.554 7.316-25.805 7.316-14.25 0-25.804-3.275-25.804-7.316C6.23 3.275 17.783 0 32.034 0 46.285 0 57.84 3.275 57.84 7.315z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#302B26"
        fillRule="evenodd"
        d="M52.696 7.315c0 2.582-9.25 4.674-20.662 4.674-11.41 0-20.661-2.092-20.661-4.674 0-2.58 9.25-4.673 20.661-4.673 11.411 0 20.662 2.092 20.662 4.673z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#6B42EE"
        fillRule="evenodd"
        d="M58.922 36.878l-4.87 37.318c-.434 3.956-9.858 7.161-22.018 7.161s-21.583-3.205-22.017-7.16l-4.87-37.32s2.56 8.077 26.887 8.077 26.888-8.076 26.888-8.076z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#694C39"
        fillRule="evenodd"
        d="M58.958 36.42c0 .152-.012.303-.036.453v.005c-.079.6-.454 1.213-.454 1.213-2.398 4.037-13.322 7.086-26.434 7.086-13.112 0-24.036-3.049-26.433-7.086 0 0-.376-.614-.454-1.213v-.005c-.024-.15-.036-.301-.036-.453 0-.465.162-.832.162-.832l.012.09c.265 2.04 1.014 2.959 2.277 3.9 4.114 3.068 13.523 5.213 24.472 5.213 10.95 0 20.359-2.145 24.473-5.213 1.262-.942 2.011-1.86 2.277-3.9l.012-.09s.162.367.162.832z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M41.432 59.814c.04 9.32-9.625 12.315-9.625 12.315s-9.666-2.995-9.626-12.315c.007-1.547.981-3.483 2.352-4.365 1.372-.883 3.271-1.12 4.743-.39 1.31.649 2.1 1.922 2.53 3.256.431-1.334 1.222-2.607 2.532-3.256 1.471-.73 3.37-.493 4.742.39 1.371.882 2.346 2.818 2.352 4.365z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

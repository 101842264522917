export const WestSvg = ({ opacity = "1", color = "#000" }: { opacity?: string; color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="29" fill="none" viewBox="0 0 47 33" opacity={opacity}>
      <path
        stroke={color}
        strokeWidth="2"
        d="M4.152 5.205c-.615 0-1.341-1.14-1.628-1.71-3.076 11.698-.724 11.788.362 14.488 1.085 2.7.226 3.824 1.266 3.824s2.443.495 3.754 1.215c1.312.72 5.428.99 6.695 1.125 1.266.135.768 3.645 1.809 3.645 1.04 0 2.261-2.295 2.578-.72.271 3.15 2.94 5.174 3.618 4.904.679-.27.09-1.665.634-3.06.542-1.394 2.397-1.35 3.527-2.204 1.131-.855 3.257-.945 5.564-1.215 2.307-.27 2.442.81 3.8.81 1.356 0 1.99 5.354 4.16 4.454 2.171-.9.588-2.79-1.356-5.984-1.556-2.556 1.492-3.807 2.713-6.12.285-.54-.733-2.114-.769-3.374-.045-1.574 2.714-3.014 3.076-3.734.362-.72-.814-.675.452-2.115 1.267-1.44 2.486-4.488.679-5.22-1.874-.757-3.754 3.915-4.795 3.915-1.04 0-1.085 1.215-1.356 2.295-.272 1.08-2.262 2.655-4.162 2.745-1.9.09-.271-2.835-1.719-4.5-1.447-1.665-2.442-.36-4.84-.54-2.396-.18-1.808-2.294-3.165-2.294C12.7 5.925 7.092 3 5.599 3c-1.492 0-.678 2.205-1.447 2.205z"
        opacity="0.4"
      ></path>
      <path
        stroke={color}
        strokeWidth="2"
        d="M4.152 5.205c-.615 0-1.341-1.14-1.628-1.71-3.076 11.698-.724 11.788.362 14.488 1.085 2.7.226 3.824 1.266 3.824s2.443.495 3.754 1.215c1.312.72 5.428.99 6.695 1.125 1.266.135-.101 6.353 3.899 2.353v-21C12.5 5.205 6.58 3 5.6 3 4.107 3 4.92 5.205 4.152 5.205z"
      ></path>
    </svg>
  );
};

export const DesertSvg = ({ selected }: { selected: boolean }) => {
  return (
    <svg
      className={selected ? "" : "opacity-60"}
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="34"
      fill="none"
      viewBox="0 0 39 32"
    >
      <path
        stroke="#000"
        strokeWidth="2"
        d="M0 30.444h39M25.81 30.444c-1.429-6.342-2.762-19.622 1.428-19.622 4.19 0 2.857 13.28 1.905 19.622M29.619 26.282l2.381.594M30.095 21.525L32 22.12M30.095 16.768L32 16.174M29.143 13.2l2.38-2.972M26.762 10.822V8.444M25.333 12.606l-2.38-1.19M24.381 16.174L22 15.579M24.381 19.741H22M24.857 23.903l-2.38.595"
      ></path>
      <circle cx="9" cy="9.444" r="8" stroke="#000" strokeWidth="2" opacity="0.6"></circle>
    </svg>
  );
};

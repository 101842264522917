import { LinkButton } from "components/common/button";
import Carousel from "components/common/carousel";
import { Chips } from "components/common/chips";
import { JobTag } from "components/common/job-tag";
import { range } from "lib/utils";
import Image from "next/legacy/image";
import Link from "next/link";
import PeopleNetworkIcon from "public/images/icons/people-network.svg";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { UrlObject } from "url";

export const JobCard = ({ job, href }: { job: any; href: UrlObject | string }) => {
  const DAYS_RANGE = 3;
  const [randomImages, setRandomImages] = useState<string[]>([]);
  const options: any = { loop: true };
  const slideCount = job.location.state.stateImages.length >= 4 ? 4 : job.location.state.stateImages.length;
  const startDate = new Date(job.startDate);
  const dateFormat = { month: "long" as const, day: "numeric" as const };
  const jobStartDate = startDate.toLocaleDateString("en-US", dateFormat);
  const shiftHours = Math.floor(job.hoursPerWeek / job.hoursPerShift);
  const jobDescription = [
    `${shiftHours} x ${job.hoursPerShift}hr`,
    `Starts ${jobStartDate}`,
    `${job.contractLengthInWeeks} weeks`,
  ];
  const slides = Array.from(Array(slideCount).keys());
  const stateImages = job.location.state.stateImages;
  const isCompetitiveJob = job.isCompetitive;

  const getRandomStateImages = () => {
    const stateImagesCopy = stateImages.slice();
    const randomImages = [];

    for (let i = 0; i < slideCount; i++) {
      const randomIndex = Math.floor(Math.random() * stateImagesCopy.length);
      randomImages.push(stateImagesCopy[randomIndex]);
      stateImagesCopy.splice(randomIndex, 1);
    }
    return randomImages;
  };

  useEffect(() => {
    // This line prevents infinite scrolling call to randomize images already loaded.
    if (randomImages.length === 0 && stateImages.length > 0) {
      const selectedImages = getRandomStateImages();
      setRandomImages(selectedImages);
    }
  }, [randomImages, stateImages]);

  return (
    <Link href={href} className="w-full self-stretch justify-self-stretch">
      <div className="w-full self-stretch justify-self-stretch sm:mr-4 mb-4 shrink-0 relative">
        {isCompetitiveJob && <JobTag icon={<PeopleNetworkIcon />} label="Competitive job" />}
        <Carousel images={randomImages} slides={slides} options={options} />
        <div className="mt-4 mb-2">
          <span className="font-medium text-3xl mb-2">{job.location.city}</span>
          <span className="text-black/60 text-3xl mb-2"> {job.location.state.name}</span>
        </div>
        <p className="text-black/60 mb-2">{job.specialty.name}</p>
        <div className="w-full">
          <Chips>
            <span className="text-black font-semibold">{`$${job.payRate}`}</span>/week
          </Chips>
          {jobDescription.map((description: string, index: number) => {
            return <Chips key={index}>{description}</Chips>;
          })}
        </div>
      </div>
    </Link>
  );
};

export function JobCardSkeleton() {
  return (
    <>
      {range(50).map((index) => (
        <div key={index} className="w-full self-stretch justify-self-stretch sm:mr-4 mb-4 shrink-0">
          <Skeleton className="rounded-3xl h-48 sm:h-80" width="100%" />
          <div className="mt-4 mb-2">
            <span className="mb-2">
              <Skeleton />
            </span>
            <span className="mb-2">
              <Skeleton />
            </span>
          </div>
          <p className="mb-2">
            <Skeleton />
          </p>
          <div className="w-full">
            <Chips>
              <Skeleton width={50} />
            </Chips>
            {range(5).map((index) => {
              return (
                <Chips key={index}>
                  <Skeleton width={50} />
                </Chips>
              );
            })}
          </div>
        </div>
      ))}
    </>
  );
}

export function JobListEmptyState() {
  return (
    <div className="flex flex-col gap-6">
      <div className="bg-white flex flex-col justify-center px-4 py-5 sm:px-8 sm:py-10 rounded-2xl">
        <Image src="/images/clipart/404.svg" alt="Not found" width={200} height={265} />
        <div className="flex flex-col sm:px-20 mt-8">
          <h2 className="text-3xl font-bold text-center mb-4">No jobs match your search</h2>
          <div className="text-center">Try updating your search filters</div>
          <div className="pt-8 my-0 mx-auto">
            <LinkButton className="px-5" href={{ pathname: "/", query: {} }} label="Clear filters" />
          </div>
        </div>
      </div>
    </div>
  );
}

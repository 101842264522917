import * as Sentry from "@sentry/nextjs";
import { range } from "lib/utils";
import Image from "next/legacy/image";
import { useRouter } from "next/router";
import Skeleton from "react-loading-skeleton";

export const StateCard = ({
  id,
  state,
  contracts,
  averagePayment,
  images,
}: {
  id: string;
  state: string;
  contracts: number;
  averagePayment: number;
  images: string[];
}) => {
  const router = useRouter();

  const getCoverImage = () => {
    if (images.length) {
      return images[0];
    }
    const errorMessage = "No cover image found";
    Sentry.captureException(new Error(errorMessage));
    return "/images/stock/jobs/job-main-01.png";
  };

  const handleStateFilter = (id: string) => {
    return router.push({
      pathname: router.pathname,
      query: { ...router.query, states: id },
    });
  };

  return (
    <button onClick={() => handleStateFilter(id)} className="shrink-0 w-72 sm:w-80 mr-4">
      <Image
        className="rounded-3xl h-48 w-full object-cover object-center"
        src={getCoverImage()}
        alt="Image 1"
        width={326}
        height={326}
      />
      <p className="font-medium text-xl mb-1 mt-1 text-left">{state}</p>
      <p className="text-black/60 mb-1 text-left">{contracts} contracts</p>
      <p className="text-black/60 mb-1 text-left">${averagePayment}/week avg</p>
    </button>
  );
};

export function StateCardSkeleton() {
  return (
    <>
      {range(50).map((index) => (
        <div key={index} className="shrink-0 w-72 sm:w-80 mr-4">
          <Skeleton className="rounded-3xl w-72 sm:w-80" height={326} />
          <Skeleton className="w-28 sm:w-32 mb-1 mt-2" />
          <Skeleton className="w-28 sm:w-32 mb-1" />
          <Skeleton className="w-28 sm:w-32 mb-1" />
        </div>
      ))}
    </>
  );
}

export const HighestPaySvg = ({ selected }: { selected: boolean }) => {
  return (
    <svg
      className={`${selected ? "" : "opacity-60"} mt-2`}
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="24"
      fill="none"
      viewBox="0 0 30 27"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7.545 1.444v24M13 5.808H4.818a3.818 3.818 0 000 7.636h5.455a3.818 3.818 0 010 7.636H1"
      ></path>
      <g stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" opacity="0.6">
        <path d="M23.364 11.262V1.444M18.455 6.353l4.909-4.91 4.909 4.91"></path>
      </g>
    </svg>
  );
};

export const LowestCostSvg = ({ selected }: { selected: boolean }) => {
  return (
    <svg
      className={selected ? "" : "opacity-60"}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="38"
      fill="none"
      viewBox="0 0 31 41"
    >
      <g stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" opacity="0.6">
        <path d="M9.073 1.444v14.933M12.467 4.16H7.376a2.376 2.376 0 100 4.75h3.394a2.376 2.376 0 110 4.752H5"></path>
      </g>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 25.577l8.372-6.511a2 2 0 012.456 0l8.372 6.511m-19.2 0v11.734a2.133 2.133 0 002.133 2.133h14.933A2.133 2.133 0 0025.2 37.31V25.577m-19.2 0v-6.2m0 6.2L1 29.51m24.2-3.933l4.466 3.4"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12.4 39.444v-7.467a3.2 3.2 0 013.2-3.2v0a3.2 3.2 0 013.2 3.2v7.467"
      ></path>
      <g stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" opacity="0.6">
        <path d="M21.97 3.577V14.05M27.206 8.814L21.97 14.05l-5.237-5.236"></path>
      </g>
    </svg>
  );
};

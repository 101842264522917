export const DollarSign = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <circle cx="12.5" cy="12.5" r="12.5" fill="#000" opacity="0.08"></circle>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12.091 5v15M15.5 7.727h-5.114a2.386 2.386 0 100 4.773h3.41a2.387 2.387 0 010 4.773H8"
      ></path>
    </svg>
  );
};

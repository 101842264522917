export const SurfingSvg = ({ selected }: { selected: boolean }) => {
  return (
    <svg
      className={selected ? "" : "opacity-60"}
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="36"
      fill="none"
      viewBox="0 0 42 39"
    >
      <path
        fill="#000"
        fillOpacity="0.2"
        d="M9.87 14.287L7.972 37.065c1.14.38 1.898.632 2.277 0 4.556-7.593 1.772-19.235-.38-22.778z"
      ></path>
      <path
        stroke="#000"
        strokeWidth="2"
        d="M9.848 13.907c4.554 11.724 3.575 17.412 0 23.537m0-23.537c-5.734 9.55-6.537 16.861-3.714 23.537m3.714-23.537L7.972 37.444M22.755 1c4.25 0 3.576 30.32 0 36.444m0-36.444c-5.672 0-6.536 29.768-3.714 36.444M22.755 1L20.88 37.444"
      ></path>
      <path
        fill="#000"
        fillOpacity="0.2"
        d="M22.327 3.278L20.5 36.876c1.097.56 1.828.933 2.193 0 4.386-11.2 3.881-38.533-.366-33.598z"
      ></path>
      <path stroke="#000" strokeWidth="2" d="M0 37.444h38.722"></path>
      <circle cx="36.306" cy="15.213" r="4.694" stroke="#000" strokeWidth="2" opacity="0.6"></circle>
    </svg>
  );
};

export const LakesSvg = ({ selected }: { selected: boolean }) => {
  return (
    <svg
      className={selected ? "" : "opacity-60"}
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 34 35"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="2"
        d="M1.2 23.844c3 0 4.08-2.4 6-2.4 2.4 0 2.4 2.4 4.8 2.4s3-2.4 5.4-2.4c2.4 0 3.6 2.4 5.4 2.4 1.8 0 2.4-2.4 4.8-2.4 1.92 0 2.4 2.4 5.4 2.4"
      ></path>
      <path
        stroke="#000"
        strokeWidth="2"
        d="M6.24 21.444V8.964m0-7.92c0 3.456 2.304 5.76 5.76 5.76m-5.76-5.76C5.964 4.489 3.456 6.804 0 6.804m6.24-5.76v7.92m0 0c.8 1.12 1.92 2.88 5.76 2.88m-5.76-2.88c-.96 1.92-3.36 2.88-6.24 2.88"
        opacity="0.6"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="2"
        d="M1.2 28.644c3 0 4.08-2.4 6-2.4 2.4 0 2.4 2.4 4.8 2.4s3-2.4 5.4-2.4c2.4 0 3.6 2.4 5.4 2.4 1.8 0 2.4-2.4 4.8-2.4 1.92 0 2.4 2.4 5.4 2.4M1.2 33.444c3 0 4.08-2.4 6-2.4 2.4 0 2.4 2.4 4.8 2.4s3-2.4 5.4-2.4c2.4 0 3.6 2.4 5.4 2.4 1.8 0 2.4-2.4 4.8-2.4 1.92 0 2.4 2.4 5.4 2.4"
      ></path>
      <path
        stroke="#000"
        strokeWidth="2"
        d="M31.2 22.644c0-3.181-2.16-8.4-8.4-8.4s-8.4 5.219-8.4 8.4"
        opacity="0.6"
      ></path>
    </svg>
  );
};

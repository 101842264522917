export const UrbanSvg = ({ selected }: { selected: boolean }) => {
  return (
    <svg
      className={selected ? "" : "opacity-60"}
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="39"
      fill="none"
      viewBox="0 0 41 39"
    >
      <mask id="path-1-inside-1_8225_2264" fill="#fff">
        <rect width="12" height="20" y="19" rx="1"></rect>
      </mask>
      <rect
        width="12"
        height="20"
        y="19"
        stroke="#000"
        strokeWidth="4"
        mask="url(#path-1-inside-1_8225_2264)"
        rx="1"
      ></rect>
      <path stroke="#000" strokeLinecap="round" strokeWidth="2" d="M4 23v12M8 23v12"></path>
      <path stroke="#000" strokeWidth="2" d="M31 14.63l9 4.575V38h-9V14.63z"></path>
      <path stroke="#000" strokeWidth="2" d="M32.5 15.5l5.79-1.24a1 1 0 011.21.977V19"></path>
      <mask id="path-6-inside-2_8225_2264" fill="#fff">
        <rect width="12" height="29" x="15" y="10" rx="1"></rect>
      </mask>
      <rect
        width="12"
        height="29"
        x="15"
        y="10"
        stroke="#000"
        strokeWidth="4"
        mask="url(#path-6-inside-2_8225_2264)"
        rx="1"
      ></rect>
      <path stroke="#000" strokeLinecap="round" strokeWidth="2" d="M19 14v21M23 14v21"></path>
      <path stroke="#000" strokeWidth="2" d="M25 11V8a1 1 0 00-1-1h-6a1 1 0 00-1 1v3"></path>
      <path
        stroke="#000"
        strokeWidth="2"
        d="M18.5 7.5l.793-2.775A1 1 0 0120.254 4h1.492a1 1 0 01.961.725L23.5 7.5"
      ></path>
      <path stroke="#000" strokeLinecap="round" strokeWidth="2" d="M21 3V1"></path>
    </svg>
  );
};

export const MultipleAgenciesSvg = () => {
  return (
    <svg className="px-1" xmlns="http://www.w3.org/2000/svg" width="57" height="33" fill="none" viewBox="0 0 57 33">
      <path
        stroke="#6B42EE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M27.778 31v-3.222a6.444 6.444 0 00-6.445-6.445H8.444A6.444 6.444 0 002 27.778V31M14.889 14.889a6.444 6.444 0 100-12.889 6.444 6.444 0 000 12.889zM37.444 31v-3.222a6.444 6.444 0 00-4.833-6.235"
      ></path>
      <path
        stroke="#6B42EE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M46.278 31v-3.222a6.445 6.445 0 00-4.834-6.235"
        opacity="0.7"
      ></path>
      <path
        stroke="#6B42EE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M55.111 31v-3.222a6.444 6.444 0 00-4.833-6.235"
        opacity="0.3"
      ></path>
      <path
        stroke="#6B42EE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M26.166 2.21a6.444 6.444 0 010 12.486"
      ></path>
      <path
        stroke="#6B42EE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M35 2.21a6.444 6.444 0 010 12.486"
        opacity="0.7"
      ></path>
      <path
        stroke="#6B42EE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M43.834 2.21a6.444 6.444 0 010 12.486"
        opacity="0.3"
      ></path>
    </svg>
  );
};

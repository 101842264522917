export const TropicalSvg = ({ selected }: { selected: boolean }) => {
  return (
    <svg
      className={selected ? "" : "opacity-60"}
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="36"
      fill="none"
      viewBox="0 0 43 38"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="2"
        d="M9.366 36C8.033 32.167 7.48 23 14.48 14M15.838 16.894c1.293 2.113 2.051 5.7.52 10.107-1.913 5.508 9.232-4.995 2.416-10.721M18.397 7.072c.166-1.833-.9-5.6-6.5-6-7-.5 6 1.5 4 9M19.104 10.845c2.503-1.329 9.343-.431 8.83 6.068-.41 5.204.981-5.616-11.259-3.08M12.413 8.261c-1.167-2-6.134-3.761-9.434.739-5.5 7.5 1.434-2.239 8.934 1.761M10.412 13C7.14 13.168 2.7 15.5 2.7 20.541c0 7.346.566-2.527 8.279-5.041M1 37h41"
      ></path>
      <path
        stroke="#000"
        strokeWidth="2"
        d="M37.8 36.4c0-3.181-2.16-8.4-8.4-8.4S21 33.219 21 36.4"
        opacity="0.6"
      ></path>
    </svg>
  );
};

export const CompassSvg = () => {
  return (
    <svg className="px-1" xmlns="http://www.w3.org/2000/svg" width="39" height="39" fill="none" viewBox="0 0 39 39">
      <rect width="36" height="36" x="1.5" y="1.5" stroke="#6B42EE" strokeWidth="3" rx="11.5"></rect>
      <path
        fill="#6B42EE"
        d="M26.277 6.996l-11.932 6.482a1 1 0 00-.085 1.705l9.573 6.519a1 1 0 001.547-.648l2.359-13a1 1 0 00-1.462-1.058z"
        opacity="0.3"
      ></path>
      <path
        stroke="#6B42EE"
        strokeWidth="3"
        d="M13.978 16.909a3.5 3.5 0 011.78-2.491l10.318-5.606-2.05 11.304a3.5 3.5 0 01-1.598 2.349L11.933 28.98l2.045-12.072z"
      ></path>
    </svg>
  );
};

import { LocationCategories } from "lib/types";
import { takeOne } from "lib/utils";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export const useCategoryTab = () => {
  const [currentCategoryTab, setCurrentCategoryTab] = useState<LocationCategories>();

  const { query } = useRouter();

  useEffect(() => {
    if (!query?.locationCategoryId) return;

    const categoryKey = takeOne(query?.locationCategoryId);

    if (categoryKey && categoryKey in LocationCategories) {
      const categoryValue = LocationCategories[categoryKey as keyof typeof LocationCategories];
      setCurrentCategoryTab(categoryValue);
    }
  }, [query]);

  return currentCategoryTab;
};

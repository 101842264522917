import { MentorshipSvg } from "components/icons/mentorship";
import { ProfileSvg } from "components/icons/profile";
import { CompassSvg } from "../icons/compass";
import { MultipleAgenciesSvg } from "../icons/multiple_agencies";

export const PerksSection = () => {
  const perks = [
    {
      title: "Multiple agencies",
      content: "Search 100s of agencies to compare",
      icon: <MultipleAgenciesSvg />,
    },
    {
      title: "One profile, all agencies",
      content: "Build a single profile accepted by all agencies",
      icon: <ProfileSvg />,
    },
    {
      title: "We're not an agency",
      content: "Have someone on your side helping you as a traveler",
      icon: <CompassSvg />,
    },
    {
      title: "Mentorship",
      content: "Connect with veteran travelers and get inside knowledge",
      icon: <MentorshipSvg />,
    },
  ];

  return (
    <>
      {perks.map((perk, index) => {
        return (
          <div key={index} className="flex sm:justify-center items-center md:ml-2 md:mb-0">
            <div
              style={{ minWidth: "72px", minHeight: "72px", width: "72px", height: "72px" }}
              className="flex justify-center items-center bg-pink rounded-2xl w-16 h-16"
            >
              {perk.icon}
            </div>
            <div className="flex flex-col ml-4 break-words w-64">
              <p className="font-medium text-base 2xl:text-xl">{perk.title}</p>
              <p className="text-sm 2xl:text-base text-black/60">{perk.content}</p>
            </div>
          </div>
        );
      })}
    </>
  );
};

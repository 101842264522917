import useEmblaCarousel, { EmblaCarouselType } from "embla-carousel-react";
import useViewportWidth from "lib/client/hooks/get-viewport-width";
import { useCallback, useEffect, useState } from "react";
import { NextButton, PrevButton, usePrevNextButtons } from "./arrows-buttons";

type CarouselPropType = {
  slides: number[];
  images: string[];
  options?: any;
};

type DotPropType = {
  onClick: () => void;
  className: string;
  children?: React.ReactNode;
};

const DotButton = (props: DotPropType) => {
  const { children, ...restProps } = props;

  return (
    <button type="button" {...restProps}>
      {children}
    </button>
  );
};

const Carousel = (props: CarouselPropType) => {
  const { slides, options, images } = props;
  const imageByIndex = (index: number): string => images[index % images.length];
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
  const viewPortWidth = useViewportWidth();
  const isMobile = viewPortWidth <= 768;

  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);

  const scrollTo = useCallback((index: number) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  const handlePrevButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onPrevButtonClick();
  };
  const handleNextButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onNextButtonClick();
  };

  return (
    <>
      <div className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {slides.length &&
              slides.map((index: number) => (
                <div className="embla__slide" key={index}>
                  <img className="embla__slide__img" src={imageByIndex(index)} />
                </div>
              ))}
          </div>
        </div>
        {!isMobile && (
          <div className="embla__buttons">
            <PrevButton onClick={handlePrevButtonClick} disabled={prevBtnDisabled} />
            <NextButton onClick={handleNextButtonClick} disabled={nextBtnDisabled} />
          </div>
        )}
        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => scrollTo(index)}
              className={"embla__dot".concat(index === selectedIndex ? " embla__dot--selected" : "")}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Carousel;

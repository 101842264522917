import { CategoriesTab, CategoriesTabSkeleton } from "components/categories-tab/CategoriesTab";
import Layout from "components/common/layout";
import { BeachSvg } from "components/icons/beach";
import { CoffeeIcon } from "components/icons/coffee";
import { CountrySvg } from "components/icons/country";
import { DesertSvg } from "components/icons/desert";
import { DollarSign } from "components/icons/dollar-sign";
import { ExploreSvg } from "components/icons/explore";
import { HighestPaySvg } from "components/icons/highestPay";
import { LakesSvg } from "components/icons/lakes";
import { LowestCostSvg } from "components/icons/lowestCost";
import { MidWestSvg } from "components/icons/midwest";
import { MountainSvg } from "components/icons/mountain";
import { NewJobsSvg } from "components/icons/new-jobs";
import { NorthEastSvg } from "components/icons/northeast";
import { SouthSvg } from "components/icons/south";
import { SurfingSvg } from "components/icons/surfing";
import { TropicalSvg } from "components/icons/tropical";
import { UrbanSvg } from "components/icons/urban";
import { WestSvg } from "components/icons/west";
import { JobCard, JobCardSkeleton, JobListEmptyState } from "components/job-card/job-card";
import { PerksSection } from "components/perks/perks-section";
import { StateCard, StateCardSkeleton } from "components/state-card/StateCard";
import { FetchJobsResponse } from "lib/client/api/job";
import { swrFetcher } from "lib/client/api/utils/configure-axios";
import { useCategoryTab } from "lib/client/hooks/useCategoryTab";
import { queryObjectToQueryString } from "lib/query-helper";
import { LocationCategories } from "lib/types";
import Image from "next/legacy/image";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";
import useSWR from "swr";
import useSWRInfinite from "swr/infinite";
import InfiniteScroll from "../infinite-scroll";

type HomePage = {
  meta: { title: string; description: string };
};

type CategoriesResponse = {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  svg?: (isSelected: boolean) => React.ReactNode;
};

type StateStatisticsResponse = {
  state: { name: string; stateImages: string[]; id: string };
  avgPayRate: number;
  jobCount: number;
};

export default function HomePage({ meta }: HomePage) {
  const { query } = useRouter();
  const { data: categoriesData } = useSWR<CategoriesResponse[]>("/api/categories");
  const { data: stateStatistics } = useSWR<StateStatisticsResponse[]>("/api/state-statistics");

  const currentCategoryTab = useCategoryTab();

  const [selectedIndex, setSelectedIndex] = useState<number>(currentCategoryTab || 0);

  const INACTIVE_OPACITY = "0.6";

  const categoriesIcon = [
    {
      explore: (selected: boolean) => <ExploreSvg opacity={selected ? "" : INACTIVE_OPACITY} />,
      urban: (selected: boolean) => <UrbanSvg selected={selected} />,
      country: (selected: boolean) => <CountrySvg selected={selected} />,
      new: (selected: boolean) => <NewJobsSvg className={"ml-2 mt-2"} opacity={selected ? "" : INACTIVE_OPACITY} />,
      cost_of_living: (selected: boolean) => <LowestCostSvg selected={selected} />,
      highest_pay: (selected: boolean) => <HighestPaySvg selected={selected} />,
      west: (selected: boolean) => <WestSvg opacity={selected ? "" : INACTIVE_OPACITY} />,
      northeast: (selected: boolean) => <NorthEastSvg opacity={selected ? "" : INACTIVE_OPACITY} />,
      midwest: (selected: boolean) => <MidWestSvg opacity={selected ? "" : INACTIVE_OPACITY} />,
      south: (selected: boolean) => <SouthSvg opacity={selected ? "" : INACTIVE_OPACITY} />,
      surfing: (selected: boolean) => <SurfingSvg selected={selected} />,
      mountains: (selected: boolean) => <MountainSvg selected={selected} />,
      beach: (selected: boolean) => <BeachSvg selected={selected} />,
      lakes: (selected: boolean) => <LakesSvg selected={selected} />,
      tropical: (selected: boolean) => <TropicalSvg selected={selected} />,
      desert: (selected: boolean) => <DesertSvg selected={selected} />,
    },
  ];
  const explore = [{ id: 0, name: "explore", svg: categoriesIcon[0].explore }];
  const categoriesWithIcons = useMemo(() => {
    return categoriesData?.map((category) => {
      return { ...category, svg: (categoriesIcon[0] as any)[category.name.replace(/\s+/g, "_")] };
    });
  }, [categoriesData, currentCategoryTab]);
  const categories = [...explore, ...(categoriesWithIcons || [])];

  const isReachingEnd = (page: FetchJobsResponse) => page.jobs.length === 0 || page.jobs.length < page.itemsPerPage + 1;

  const getKey = (pageIndex: number, previousPageData: FetchJobsResponse) => {
    if (previousPageData && isReachingEnd(previousPageData)) return null;

    const queryObject: Record<string, any> = { ...query, page: String(pageIndex) };
    queryObject.locationCategoryId = LocationCategories[queryObject.locationCategoryId];
    const queryString = queryObjectToQueryString(queryObject);
    return `/api/jobs?${queryString}`;
  };

  const swr = useSWRInfinite<FetchJobsResponse>(getKey, swrFetcher, { revalidateFirstPage: false });
  const { data, error } = swr;

  if (error) return <></>;

  return (
    <Layout globalNavConfig={{ showGlobalFiltersMobile: true }} meta={meta}>
      <div className="h-screen">
        {categoriesData?.length ? (
          <CategoriesTab setSelectedIndex={setSelectedIndex} selectedIndex={selectedIndex} categories={categories} />
        ) : (
          <CategoriesTabSkeleton />
        )}
        <div>
          {!Object.keys(query).length && (
            <>
              <div className="flex mx-4 sm:mx-8 py-8 justify-center items-center">
                <div className="mr-4">
                  <p className="font-medium text-2xl mb-2">Coffee on us when you chat with an employer</p>
                  <p className="text-black/60">Earn $5. Must be a qualified clinician*</p>
                </div>
                <div className="w-16 h-24">
                  <CoffeeIcon />
                </div>
              </div>
              <hr className="mb-8" />
              <div className="mx-4 sm:mx-8">
                <h2 className="font-medium text-2xl">Apply to travel contracts from 100s of agencies</h2>

                <a
                  className="inline-flex bg-sharpie font-medium rounded-3xl py-1.5 px-2.5 mt-3.5"
                  href="mailto:hello@vettedhealth.com?subject=Coffee%20on%20us&body=Hello!%20I%20applied%20to%20a%20job%20and%20chatted%20with%20an%20employer,%20can%20I%20get%20my%20coffee?"
                >
                  <div className="mr-1">
                    <DollarSign />
                  </div>
                  <p>Earn $5 when you apply and chat</p>
                </a>
              </div>
              <div className="w-screen">
                <div className="flex hide-scrollbar w-full sm:pl-8 pl-4 overflow-x-scroll mt-6">
                  {stateStatistics ? (
                    stateStatistics.map((stateData) => {
                      return (
                        <StateCard
                          key={stateData.state.id}
                          id={stateData.state.id}
                          state={stateData.state.name}
                          contracts={stateData.jobCount}
                          averagePayment={stateData.avgPayRate}
                          images={stateData.state.stateImages}
                        />
                      );
                    })
                  ) : (
                    <StateCardSkeleton />
                  )}
                </div>
              </div>
              <hr className="mt-10" />
              <div className="mx-4 sm:mx-8">
                <h3 className="font-medium text-xl my-6 sm:my-11">What Vetted brings to the table.</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 sm:gap-4">
                  <PerksSection />
                </div>
                <div
                  className="
              flex 
              flex-col 
              justify-center 
              items-center 
              w-full 
              shadow
              border 
              border-lightGrey/60 
              rounded-2xl 
              py-6 
              mt-10
              mb-8 bg-white"
                >
                  <Image width={240} height={70} src={"/images/customers/recruiters_complete.png"}></Image>
                  <p className="font-medium text-xl mt-4 sm:text-3xl">Trusted by 500K clinicians</p>
                </div>
                <hr />
              </div>
            </>
          )}
          <h3 className="mx-4 sm:mx-8 font-medium py-8 text-xl">For you</h3>
          <div className="mx-4 gap-x-6 sm:mx-8 grid-container-m place-items-center">
            {data ? (
              data.length === 0 || data[0].jobs.length === 0 ? (
                <div className="mx-4 sm:mx-8 col-span-full">{JobListEmptyState()}</div>
              ) : (
                <InfiniteScroll swr={swr} isReachingEnd={() => isReachingEnd(data[data.length - 1])}>
                  {(page) =>
                    page.jobs.map(
                      (job, index) =>
                        index < page.itemsPerPage &&
                        job.id && <JobCard href={{ pathname: `/job/${job.id}`, query }} key={job.id} job={job} />
                    )
                  }
                </InfiniteScroll>
              )
            ) : (
              JobCardSkeleton()
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const ExploreSvg = ({ opacity = "1", color = "#000" }: { opacity?: string; color?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="40" fill="none" viewBox="0 0 45 20" opacity={opacity}>
    <g stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" opacity="0.4">
      <path d="M8.5 5.672V24.36l8.176-4.672 9.344 4.672 8.176-4.672V1L26.02 5.672 16.676 1 8.5 5.672zM16.676 1v18.688M26.02 5.672V24.36"></path>
    </g>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth="4"
      d="M2 14c6 5 26.628.336 22.5-4.5-4.75-5.565-8.375 10.033-.875 8.44 7.5-1.595 17.75-9.432 18.875-11.026m0 0h-4m4 0V10.5"
    ></path>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeWidth="2"
      d="M2 14c6 5 26.628.336 22.5-4.5-4.75-5.565-8.375 10.033-.875 8.44 7.5-1.595 17.75-9.432 18.875-11.026m0 0h-4m4 0V10.5"
    ></path>
  </svg>
);

import { Tab } from "@headlessui/react";
import { LocationCategories } from "lib/types";
import { range, takeOne } from "lib/utils";
import { useRouter } from "next/router";
import { ParsedUrlQuery } from "querystring";
import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";

type Categories = {
  id: number;
  createdAt?: string;
  updatedAt?: string;
  name: string;
  svg?: (isSelected: boolean) => React.ReactNode;
};

export const CategoriesTab = ({
  categories,
  setSelectedIndex,
  selectedIndex,
}: {
  categories: Categories[];
  setSelectedIndex: (value: number) => void;
  selectedIndex: number;
}) => {
  const router = useRouter();
  const { query } = router;
  const [borderStyle, setBorderStyle] = useState<React.CSSProperties>({});
  const tabsRef = useRef<(HTMLElement | null)[]>([]);

  const addCategoryParam = (index: number) => {
    switch (categories[index].id) {
      case LocationCategories.explore:
        delete router.query.isNewJob;
        delete router.query.locationCategoryId;
        delete router.query.isHighestPay;
        return router.push({ pathname: router.pathname, query });
      case LocationCategories.highestPay:
        delete router.query.locationCategoryId;
        delete router.query.isNewJob;
        return router.push({
          pathname: router.pathname,
          query: { ...router.query, isHighestPay: true },
        });
      case LocationCategories.new:
        delete router.query.locationCategoryId;
        delete router.query.isHighestPay;
        return router.push({
          pathname: router.pathname,
          query: { ...router.query, isNewJob: true },
        });
      default:
        delete router.query.isHighestPay;
        delete router.query.isNewJob;
        return router.push({
          pathname: router.pathname,
          query: { ...router.query, locationCategoryId: LocationCategories[categories[index].id] },
        });
    }
  };

  const updateSelector = (index: number) => {
    addCategoryParam(index);
    setSelectedIndex(index);
    if (tabsRef.current[index]) {
      const tabRect = tabsRef.current[index]!.getBoundingClientRect();
      const containerRect = tabsRef.current[index]!.parentElement!.getBoundingClientRect();
      const scrollPosition = tabsRef.current[index]!.parentElement!.scrollLeft;

      const relativeLeft = tabRect.left - containerRect.left;
      setBorderStyle({
        width: tabRect.width,
        transform: `translateX(${relativeLeft + scrollPosition}px)`,
      });
    }
  };

  const parseQueryParam = (query: ParsedUrlQuery): ParsedUrlQuery | string => {
    if (query.isHighestPay) {
      return "highestPay";
    } else if (query.isNewJob) {
      return "new";
    } else if (query.locationCategoryId) {
      return takeOne(query.locationCategoryId);
    } else {
      return query;
    }
  };

  useEffect(() => {
    const categoryQueryParam = parseQueryParam(query);
    const categoryIndex = LocationCategories[categoryQueryParam as keyof typeof LocationCategories];
    updateSelector(categoryIndex || 0);
  }, []);

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  return (
    <Tab.Group
      onChange={(index) => {
        updateSelector(index);
      }}
      selectedIndex={selectedIndex}
    >
      <div className="2xl:flex 2xl:justify-center 2xl:items-center hide-scrollbar relative overflow-x-scroll border-b border-zinc-200 pt-4 pb-3 sm:px-8 px-4">
        <Tab.List className="flex">
          <div className="absolute bottom-0 h-0.5 bg-black transition-transform duration-300" style={borderStyle}></div>
          {categories?.map((category, index) => {
            const isLastCategoryElement = index === categories.length - 1;
            return (
              <Tab
                ref={(el) => (tabsRef.current[index] = el)}
                key={index}
                className={({ selected }) =>
                  `${selected ? "outline-transparent outline-none" : "border-none text-black/60"}`
                }
              >
                <div
                  className={`flex flex-col items-center justify-around mx-4 w-max h-full ${
                    isLastCategoryElement ? "sm:mr-8 mr-4" : ""
                  }`}
                >
                  <div className="mb-auto">{category.svg && category.svg(index === selectedIndex)}</div>
                  <span className="mt-1">{capitalizeFirstLetter(category.name)}</span>
                </div>
              </Tab>
            );
          })}
        </Tab.List>
      </div>
    </Tab.Group>
  );
};

export function CategoriesTabSkeleton() {
  return (
    <div className="2xl:flex 2xl:justify-center 2xl:items-center hide-scrollbar relative overflow-x-scroll border-y border-zinc-200 pt-4 pb-3 sm:px-8 px-4">
      <div className="flex h-14">
        {range(50).map((index) => (
          <div key={index} className={`flex flex-col items-center justify-around mx-4 w-max h-full`}>
            <Skeleton className="w-14 h-14 mb-auto" />
            <Skeleton className="w-14 h-2 mt-1" />
          </div>
        ))}
      </div>
    </div>
  );
}

export const MentorshipSvg = () => {
  return (
    <svg className="px-1" xmlns="http://www.w3.org/2000/svg" width="50" height="42" fill="none" viewBox="0 0 50 42">
      <path
        stroke="#6B42EE"
        strokeLinecap="round"
        strokeWidth="3"
        d="M36.53 35H2.326c-.947-6.44-.388-19.09 9.431-19.09C20.5 15.91 21.5 21.5 21.5 29"
      ></path>
      <path
        stroke="#6B42EE"
        strokeLinecap="round"
        strokeWidth="3"
        d="M26.19 40h20.908c1.213-6.364.934-18.864-9.886-18.864-8.75 0-11.023 6.819-11.023 10.228"
        opacity="0.6"
      ></path>
      <circle cx="37.098" cy="11.25" r="4.068" stroke="#6B42EE" strokeWidth="3" opacity="0.6"></circle>
      <circle cx="12.326" cy="5.568" r="4.068" stroke="#6B42EE" strokeWidth="3"></circle>
    </svg>
  );
};

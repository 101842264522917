export const MountainSvg = ({ selected }: { selected: boolean }) => {
  return (
    <svg
      className={selected ? "" : "opacity-60"}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="31"
      fill="none"
      viewBox="0 0 34 35"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="2"
        d="M2 26.104c2.918 0 3.969-1.375 5.837-1.375 2.334 0 2.334 1.375 4.669 1.375 2.335 0 2.918-1.375 5.253-1.375 2.334 0 3.502 1.375 5.253 1.375 1.75 0 2.334-1.375 4.669-1.375 1.868 0 2.335 1.375 5.253 1.375M2 29.779c2.918 0 3.969-1.375 5.837-1.375 2.334 0 2.334 1.375 4.669 1.375 2.335 0 2.918-1.375 5.253-1.375 2.334 0 3.502 1.375 5.253 1.375 1.75 0 2.334-1.375 4.669-1.375 1.868 0 2.335 1.375 5.253 1.375M2 33.93c2.918 0 3.969-1.375 5.837-1.375 2.334 0 2.334 1.375 4.669 1.375 2.335 0 2.918-1.375 5.253-1.375 2.334 0 3.502 1.375 5.253 1.375 1.75 0 2.334-1.375 4.669-1.375 1.868 0 2.335 1.375 5.253 1.375"
      ></path>
      <path
        stroke="#000"
        strokeWidth="2"
        d="M21.354 15.105C17.268 12.659 13.66 7.57 13.66 7.57S9.958 17.47 2.925 25.614m7.981-12.03c.008 1.375.863 2.467 2.238 2.467 1.719 0 1.719-1.878 3.437-1.878 1.719 0 1.164.337 2.538.337"
      ></path>
      <circle cx="4.5" cy="6.944" r="3.5" stroke="#000" strokeWidth="2" opacity="0.6"></circle>
      <path
        stroke="#000"
        strokeWidth="2"
        d="M32.5 26.077c-2.072-2.526-3.436-6.096-4.938-10.642m-8.443-1.85c.009 1.374.476 1.388 1.851 1.388 1.719 0 1.52-1.851 3.239-1.851.925 0 1.224.463 3.353 2.313M11.254 26.077c7.033-8.143 12.492-22.209 12.492-22.209s1.466 4.456 3.816 11.567"
      ></path>
    </svg>
  );
};

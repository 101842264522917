import HomePage from "components/common/layout/home-page";
import { useCheckRedirect } from "lib/client/hooks/session-user";

export default function Home() {
  const { isLoading } = useCheckRedirect();
  if (isLoading) return <></>;

  return (
    <HomePage
      meta={{
        title: "Compare Healthcare Pay Packages",
        description: "Compare healthcare pay packages and get matched to the highest paying healthcare jobs in America",
      }}
    />
  );
}

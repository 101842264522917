export const RightArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" fill="none" viewBox="0 0 44 44">
      <g opacity="0.9">
        <g filter="url(#filter0_d_7965_3242)">
          <circle cx="22" cy="20" r="20" fill="#fff"></circle>
        </g>
        <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 14l6 6-6 6"></path>
      </g>
      <defs>
        <filter
          id="filter0_d_7965_3242"
          width="44"
          height="44"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_7965_3242"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_7965_3242" result="shape"></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

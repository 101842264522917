export const CountrySvg = ({ selected }: { selected: boolean }) => {
  return (
    <svg
      className={selected ? "" : "opacity-60"}
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="38"
      fill="none"
      viewBox="0 0 32 41"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="2"
        d="M1 31.944c2.5-.167 8.8 0 14 2 6.5 2.5 5 3.5 15.5 5.5M30.5 27.282c-2.833-2.166-11-4.2-21 5"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14.395 14.988l4.33-3.368a2 2 0 012.455 0l4.33 3.368m-11.116 0v8.793m0-8.793v-3.59m0 3.59L11.5 17.265m14.01-2.277v9.565m0-9.565l2.586 1.968"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="2"
        d="M14.395 8.237c0-.777-1.236-.97-1.236-1.747 0-.776 1.236-1.164 1.236-1.747 0-.582-1.236-.776-1.236-1.552 0-.621 1.236-.777 1.236-1.747"
        opacity="0.6"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18.1 25.944v-5.251c0-1.023.83-1.853 1.852-1.853v0c1.023 0 1.853.83 1.853 1.853v4.323"
        opacity="0.6"
      ></path>
      <path stroke="#000" strokeLinecap="round" strokeWidth="2" d="M17 26.944c-1.667-2.5-7.1-7.2-15.5-6"></path>
    </svg>
  );
};
